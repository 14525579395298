/** @jsx jsx */
import { jsx } from "theme-ui";
import DLWLayout from "../../layouts/dlw-mobile";
import ChangePassword from "../../components/ChangePassword";

function PasswordChange() {
  return (
    <DLWLayout>
      <ChangePassword />
    </DLWLayout>
  );
}

export default PasswordChange;

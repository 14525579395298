/** @jsx jsx */
import { jsx, Button, Text, Message, Spinner } from "theme-ui";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import TimedAlert from "sites-common/components/ui/TimedAlert";
import FormContext from "./FormContext";

function ErrorOrLoading() {
  const {
    apiInProgress,
    isFormErrors,
    isErrorMissingFields,
    apiError,
    formDefinition,
  } = React.useContext(FormContext);

  if (apiInProgress) {
    return <Spinner />;
  }

  if (isErrorMissingFields) {
    return (
      formDefinition.errorTextMissingFields !== null && (
        <Message>
          {formDefinition.errorTextMissingFields
            ? formDefinition.errorTextMissingFields
            : "All fields marked * are mandatory"}
        </Message>
      )
    );
  }
  if (isFormErrors) {
    return (
      formDefinition.errorTextInvalidFields !== null && (
        <Message>
          {formDefinition.errorTextInvalidFields
            ? formDefinition.errorTextInvalidFields
            : "Please fix the form errors to continue saving"}
        </Message>
      )
    );
  }

  if (apiError) {
    return <Message>{apiError}</Message>;
  }

  return null;
}

const SubForm = ({
  title,
  name,
  allwaysOpen,
  hideChange,
  savedMessage,
  saveBtnTxt,
  discardBtnTxt,
  hideDiscard,
  ...props
}) => {
  const {
    saveForm,
    resetForm,
    openSection,
    setOpenSection,
    isChanged,
    isFormErrors,
    recentlySavedSection,
  } = React.useContext(FormContext);
  const openMe = useCallback(
    () => setOpenSection(name),
    [setOpenSection, name]
  );
  const showSave = isChanged && !isFormErrors;
  const discard = useCallback(() => {
    resetForm();
    if (allwaysOpen) {
      openMe();
    }
  }, [resetForm, allwaysOpen, openMe]);

  return (
    <div
      sx={{ bg: openSection === name ? "muted" : "background", p: 2, my: 2 }}
    >
      <table sx={{ mb: 2, width: "100%" }}>
        <tbody>
          <tr>
            <td> {title && <Text variant="sectionTitle">{title}</Text>}</td>
            <td align="right">
              {openSection !== name &&
                !isChanged &&
                (!hideChange || !allwaysOpen) &&
                setOpenSection && (
                  <Button variant="mutedFit" onClick={openMe}>
                    change
                  </Button>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <div {...props} />
            </td>
          </tr>
        </tbody>
      </table>
      <TimedAlert
        displayIf={recentlySavedSection === name}
        time={2000}
        variant="notify"
      >
        {savedMessage || "Saved"}
      </TimedAlert>
      {openSection === name && (
        <>
          <ErrorOrLoading />
          <div sx={{ my: 2 }}>
            {!hideDiscard && (
              <Button variant="secondaryFit" onClick={discard} sx={{ mr: 2 }}>
                {discardBtnTxt}
              </Button>
            )}
            {showSave && (
              <Button
                variant={showSave ? "primaryFit" : "primaryFitDisabled"}
                disabled={!showSave}
                sx={{ ml: 2 }}
                onClick={saveForm}
              >
                {saveBtnTxt}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

SubForm.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hideChange: PropTypes.bool,
  allwaysOpen: PropTypes.bool,
  savedMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  saveBtnTxt: PropTypes.string,
  discardBtnTxt: PropTypes.string,
  hideDiscard: PropTypes.bool,
};
SubForm.defaultProps = {
  title: "",
  hideChange: false,
  allwaysOpen: false,
  savedMessage: undefined,
  saveBtnTxt: "Save",
  discardBtnTxt: "Discard",
  hideDiscard: false,
};

export default SubForm;

/** @jsx jsx */
import { jsx, Alert, Close } from "theme-ui";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

const TimedAlert = ({ displayIf, time, ...restprops }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (displayIf) {
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, time);
    }
  }, [displayIf, time]);
  const hide = useCallback(() => {
    setShow(false);
  }, []);

  return show && displayIf ? (
    <Alert {...restprops}>
      {restprops.children}
      <Close sx={{ cursor: "pointer" }} ml="auto" mr={-2} onClick={hide} />
    </Alert>
  ) : null;
};
TimedAlert.propTypes = {
  displayIf: PropTypes.bool.isRequired,
  time: PropTypes.number,
};

TimedAlert.defaultProps = {
  time: 2000,
};

export default TimedAlert;

import { useCallback, useEffect, useState, useMemo, useReducer } from "react";
import useMyState from "sites-common/hooks/useMyState";
import { get, compact, set } from "sites-common/utils/lodash";
import useSrcmApi1 from "../../hooks/useSrcmApi1";

import validateInputField from "./validateInputField";

function reducer(state, action) {
  let ret;
  switch (action.type) {
    case "addSectionInput":
      ret = { ...state };
      set(ret, [action.section, action.input], 1);
      return ret;

    default:
      throw new Error();
  }
}

function useSectionInputs(d = {}) {
  const [s, dispatch] = useReducer(reducer, d);

  const addSectionInput = useCallback(
    (section, input) => dispatch({ type: "addSectionInput", section, input }),
    []
  );

  return [s, addSectionInput];
}

function checkError(fd, val) {
  const fieldType = get(fd, "type", "text");
  const isRequired = get(fd, "required", true);
  const validator = get(fd, "validator");
  const e = validateInputField(fieldType, isRequired, val, validator);
  return e;
}

const validateBeforeSubmit = (
  changed,
  formData,
  formDefinition,
  formDataPostProcessed,
  sectionFields
) => {
  let errors = {};
  sectionFields.forEach((k) => {
    if (k in formDefinition.fields) {
      const e = checkError(formDefinition.fields[k], formDataPostProcessed[k]);
      if (e) {
        errors[k] = e;
      }
    } else {
      throw Error("Invalid Field Configuration");
    }
  });
  if (Object.keys(errors).length) {
    return errors;
  }
  Object.keys(changed)
    .filter((k) => !sectionFields.includes(k))
    .forEach((k) => {
      if (k in formDefinition.fields) {
        const e = checkError(
          formDefinition.fields[k],
          formDataPostProcessed[k]
        );
        if (e) {
          errors[k] = e;
        }
      }
    });
  if (Object.keys(errors).length) {
    return errors;
  }

  if (typeof formDefinition.validator === "function") {
    errors = formDefinition.validator(changed, formData);
  }
  return errors;
};

const getChanged = (apiData, formData, formDefinition) => {
  const changed = {};
  const r = formDefinition.transformFormDataForSaving(apiData);
  const v = formDefinition.transformFormDataForSaving(formData);

  Object.keys(formDefinition.fields).forEach((k) => {
    if (k in v) {
      const oldValue = k in r ? r[k] : undefined;
      const newValue = v[k];
      const diff =
        compact([oldValue, newValue]).length > 0 &&
        JSON.stringify(oldValue) !== JSON.stringify(newValue);
      if (diff) {
        changed[k] = v[k];
      }
    }
  });
  return [changed, v];
};

const computeChanges = (
  apiData,
  unsavedData,
  formDefinition,
  sectionFields
) => {
  if (Object.keys(unsavedData).length === 0) {
    return [apiData, {}, {}];
  }
  const formData1 = { ...apiData, ...unsavedData };
  const [changedData1, formData1PostProcessed] = getChanged(
    apiData,
    formData1,
    formDefinition
  );
  const formErrors1 = validateBeforeSubmit(
    changedData1,
    formData1,
    formDefinition,
    formData1PostProcessed,
    sectionFields
  );
  return [formData1, changedData1, formErrors1];
};

function useSrcmApiForForm(
  apiParams,
  initParams,
  formDefinition,
  initialOpenSection
) {
  const {
    apiInProgress,
    apiData: apiDataF,
    callApi,
    apiError,

    apiDataVersion,
    resetToInit,
  } = useSrcmApi1(apiParams, initParams);

  const [
    unsavedData,
    { reset: resetFormData, updateKVE: updateFormField, set: setFormFields },
  ] = useMyState({});

  const [error, setError] = useState("");
  const [sectionInputs, addSectionInput] = useSectionInputs();
  const [openSection, setOpenSection] = useState(initialOpenSection);
  const apiData = useMemo(
    () =>
      typeof formDefinition.transformApiDataOnFetching === "function"
        ? formDefinition.transformApiDataOnFetching({
            ...apiDataF,
          })
        : apiDataF,
    [apiDataF, formDefinition]
  );
  //
  useEffect(() => {
    setError(apiError.toString().replace("Error: ", ""));
  }, [apiError]);

  useEffect(() => setError(""), [unsavedData]);

  const callApi1 = useCallback(
    (method, methodParams, onSuccess1, onFailure1) => {
      const onSuccess = (p) => {
        resetFormData();
        onSuccess1(p);
      };

      callApi(method, methodParams, onSuccess, onFailure1);
    },
    [resetFormData, callApi]
  );

  const [formData, changedData, formErrors] = useMemo(
    () =>
      computeChanges(
        apiData,
        unsavedData,
        formDefinition,
        Object.keys(get(sectionInputs, openSection, {}))
      ),
    [apiData, unsavedData, formDefinition, sectionInputs, openSection]
  );

  const isApiData = Object.keys(apiData).length > 0;
  const isChanged = Object.keys(changedData).length > 0;

  useEffect(() => {
    const defaults = formDefinition?.defaults;
    if (
      !!defaults &&
      typeof defaults === "object" &&
      Object.keys(defaults).length > 0 &&
      !isApiData &&
      !isChanged
    ) {
      setFormFields(defaults);
    }
  }, [setFormFields, formDefinition, isApiData, isChanged]);

  const startOver = useCallback(() => {
    resetFormData();
    resetToInit();
    setError("");
    setOpenSection(initialOpenSection);
  }, [
    resetFormData,
    resetToInit,
    setError,
    setOpenSection,
    initialOpenSection,
  ]);

  return {
    apiInProgress,
    apiData,
    apiDataVersion,
    callApi: callApi1,
    apiError: error,

    resetFormData,
    updateFormField,
    setFormFields,
    formData,
    changedData,
    formErrors,
    isErrorMissingFields:
      Object.values(formErrors).filter((f) => f === "required").length > 0,
    isFormErrors: Object.keys(formErrors).length > 0,
    isChanged,

    openSection,
    setOpenSection,
    formDefinition,

    addSectionInput,
    startOver,
  };
}

export default useSrcmApiForForm;

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import fetchSrcmAPI from "sites-common/service-wrappers/profile-api/fetchSrcmAPI";
import { selectorSrcmToken } from "../state/selectors";

function useSrcmApi1(apiParams, initParams) {
  const {
    api,
    authToken,
    client = "baseClient",
    extraHdrs = {},
    extraData = {},
    forceAuth = false,
  } = apiParams;
  const { token: sbt1 } = useSelector(selectorSrcmToken);

  const sbt = authToken || sbt1;

  const [apiInProgress, setInProgress] = useState(true);
  const [apiData, setRaw] = useState({});
  const [apiError, setError] = useState("");

  const [apiDataVersion, setApiDataVersion] = useState(0);

  const callApi = useCallback(
    (method, methodParams, onSuccess, onFailure) => {
      setInProgress(true);
      setError("");
      fetchSrcmAPI(
        api,
        sbt,
        method,
        extraHdrs,
        extraData,
        forceAuth,
        methodParams,
        client
      )
        .then((respPayload) => {
          setRaw(respPayload);
          if (onSuccess) {
            onSuccess(respPayload);
          }
          setInProgress(false);
        })
        .catch((e) => {
          const e1 = e.toString().replace("Error: ", "");
          setError(e1);
          if (onFailure) {
            onFailure(e1);
          }
          setInProgress(false);
        });
    },
    [sbt, api, client, extraData, extraHdrs, forceAuth]
  );

  useEffect(() => {
    setApiDataVersion((x) => x + 1);
  }, [apiData]);

  useEffect(() => {
    if (!apiDataVersion) {
      if (initParams) {
        const { method, methodParams = {}, onSuccess, onFailure } = initParams;
        if (method) {
          callApi(method, methodParams, onSuccess, onFailure);
        }
      } else {
        setInProgress(false);
      }
    }
  }, [callApi, initParams, apiDataVersion]);

  const resetToInit = useCallback(() => {
    setRaw({});
    setError("");
    setInProgress(true);
    setApiDataVersion(0);
  }, []);

  return {
    apiInProgress,
    apiData,
    callApi,
    apiError,

    apiDataVersion,
    resetToInit,
  };
}

export default useSrcmApi1;

const transformFormDataForSaving = (r) => {
  const { password } = r;
  return { password };
};
const transformApiDataOnFetching = (r) => {
  return r;
};

const fieldDefinitionsDict = {
  password: {
    label: "Password",
    type: "password",
    required: false,
  },
  confirm_password: {
    label: "Confirm Password",
    type: "text",
    required: false,
  },
};

const formDefinition = {
  fields: fieldDefinitionsDict,
  validator: (changed, formData) => {
    const { password, confirm_password } = formData;
    const errors = {};
    if (password !== confirm_password) {
      errors.confirm_password = "Passwords do not match!";
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return false;
  },
  transformFormDataForSaving,
  transformApiDataOnFetching,
  errorTextMissingFields: "",
  errorTextInvalidFields: "",
};

export default formDefinition;

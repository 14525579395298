/** @jsx jsx */
import { jsx, Text, Alert } from "theme-ui";

import SrcmForm from "gatsby-plugin-hfn-profile/components/SrcmForm";
import { useEffect } from "react";
import useSignOut from "gatsby-plugin-hfn-profile/auth/useSignOut";
import { navigate } from "@reach/router";
import formDefinition from "./formDefinition";

const one = "one";

function ChangePassword() {
  const { doSignOut } = useSignOut();
  const srcmApiForForm = SrcmForm.useSrcmApiForForm(
    {
      api: "/api/v2/me/change_password/",
    },
    null,
    formDefinition,
    one
  );

  const { apiDataVersion } = srcmApiForForm;

  useEffect(() => {
    if (apiDataVersion > 1) {
      setTimeout(() => {
        doSignOut();
        navigate("/");
      }, 3000);
    }
  }, [apiDataVersion, doSignOut]);

  return (
    <div key={apiDataVersion}>
      <SrcmForm srcmApiForForm={srcmApiForForm} callApiMethod="POST">
        <div sx={{ textAlign: "center", my: 2 }}>
          <Text variant="title">Change Password</Text>
        </div>

        <div sx={{ textAlign: "left", mb: 3 }}>
          {apiDataVersion > 1 ? (
            <Alert variant="notify">
              Password changed successfully! <br />
              <br />
              <br />
              Sign-in with new password to continue ...
            </Alert>
          ) : (
            <SrcmForm.SubForm title="" name={one} allwaysOpen>
              <SrcmForm.InputField name="password" section={one} />
              <SrcmForm.InputField name="confirm_password" section={one} />
            </SrcmForm.SubForm>
          )}
        </div>
      </SrcmForm>
    </div>
  );
}

export default ChangePassword;

import {
  validateAbhyasiId,
  validateAbhyasiIdTemp,
  validateEmail,
  validatePhoneNumber,
} from "sites-common/utils/validations";

function validateInputField(fieldType, isRequired, val, validator) {
  if (isRequired && !val) {
    if (fieldType === "photo") {
      return "Photo Not Selected";
    }
    return "required";
  }
  if (val) {
    if (validator) {
      const e = validator(val);
      if (e) {
        return e;
      }
    }
    if (fieldType === "email" && !validateEmail(val)) {
      return "Invalid Email";
    }
    if (fieldType === "phone" && !validatePhoneNumber(val)) {
      return "Invalid Phone";
    }
    if (fieldType === "abhyasi_ref" && !validateAbhyasiId(val)) {
      return "Invalid Heartfulness Id";
    }
    if (fieldType === "abhyasi_ref_temp" && !validateAbhyasiIdTemp(val)) {
      return "Invalid Temporary Paper Card Number";
    }
  }
  return "";
}
export default validateInputField;

/** @jsx jsx */
import { jsx, Box, Text, Badge, Alert } from "theme-ui";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { get } from "sites-common/utils/lodash";
import { DynamicField } from "sites-common/components/DynamicField";
import { useSelector } from "react-redux";
import { selectorSrcmToken } from "../../state/selectors";

import FormContext from "./FormContext";

const RenderPrint = ({ label, value }) => (
  <div>
    <Text variant="printFormLabel">{label} :</Text>
    <Text variant="printFormValue"> {value}</Text>
  </div>
);

RenderPrint.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.string,
};

RenderPrint.defaultProps = {
  value: "",
};

const yn = (x) => (x ? "Yes" : "No");

const InputField = React.memo(({ name, section, authToken, ...restargs }) => {
  const { token: sbt1 } = useSelector(selectorSrcmToken);
  const context = React.useContext(FormContext);
  const sbt = authToken || sbt1;
  const {
    formData,
    updateFormField,
    formErrors,
    formDefinition,
    openSection,
    addSectionInput,
  } = context;

  const fd = formDefinition.fields[name];
  if (!fd) {
    console.error(`Field Definition for ${name} missing in formDefinition`);
  }
  const isRequired = get(fd, "required", true);

  const fieldError = get(formErrors, name, "");

  const { label, helperText, hideLabel, type } = fd;
  const edit = section ? section === openSection : true;

  useEffect(() => {
    addSectionInput(section, name);
  }, [addSectionInput, section, name]);

  return edit ? (
    <Box sx={{ mb: 2 }}>
      {!hideLabel && (
        <Text variant="subtitle">
          {label}
          {isRequired && (
            <Text
              variant={fieldError && fieldError === "required" ? "error" : ""}
              sx={{ ml: 1 }}
            >
              *
            </Text>
          )}
        </Text>
      )}
      <DynamicField
        type="text"
        variant="border-bottom"
        {...restargs}
        {...fd}
        sbt={sbt}
        name={name}
        value={formData[name]}
        onChange={updateFormField}
      />
      {helperText && (
        <Text variant="description" sx={{ mx: 1 }}>
          {helperText}
          <br />
        </Text>
      )}
      {fieldError &&
        fieldError !== "required" &&
        (fieldError.length > 60 ? (
          <Alert sx={{ ml: 1, backgroundColor: "darkred" }}>{fieldError}</Alert>
        ) : (
          <Badge variant="danger" sx={{ ml: 1 }}>
            {fieldError}
          </Badge>
        ))}
    </Box>
  ) : (
    <DynamicField
      RenderPrint={RenderPrint}
      type="text"
      {...restargs}
      {...fd}
      name={name}
      value={
        type === "toggle" || type === "checkbox"
          ? yn(formData[name])
          : formData[name]
      }
      onChange={null}
    />
  );
});

export default InputField;

/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import FormContext from "./FormContext";
import InputField from "./InputField";
import SubForm from "./SubForm";
import useSrcmApiForForm from "./useSrcmApiForForm";
// import { useDispatch } from "react-redux";
// import { showAlert } from "../../state/actions";

function SrcmForm({
  srcmApiForForm,
  callApiMethod,
  callApiSaveCallback,
  ...restprops
}) {
  // const dispatch = useDispatch();
  const { callApi, resetFormData, changedData, openSection, setOpenSection } =
    srcmApiForForm;

  const [recentlySavedSection, setRecentlySavedSection] = useState(null);

  const saveForm = useCallback(() => {
    setRecentlySavedSection(null);
    const refinedChangedData = Object.entries(changedData).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: value || null,
        };
      },
      {}
    );
    callApi(
      callApiMethod,
      refinedChangedData,
      (p) => {
        if (callApiSaveCallback) {
          callApiSaveCallback(p);
        }
        setOpenSection(null);
        setRecentlySavedSection(openSection);
      },
      () => {
        // dispatch(showAlert({message: e, title: "Form Submission Error"}));
      }
    );
  }, [
    callApi,
    changedData,
    setOpenSection,
    openSection,
    callApiMethod,
    callApiSaveCallback,
  ]);

  const resetForm = useCallback(() => {
    resetFormData();
    setOpenSection(null);
  }, [resetFormData, setOpenSection]);

  return (
    <FormContext.Provider
      value={{
        ...srcmApiForForm,
        saveForm,
        resetForm,
        recentlySavedSection,
      }}
    >
      {restprops.children}
    </FormContext.Provider>
  );
}

SrcmForm.propTypes = {
  srcmApiForForm: PropTypes.shape({
    callApi: PropTypes.func.isRequired,
    resetFormData: PropTypes.func.isRequired,
    changedData: PropTypes.shape({}).isRequired,
    openSection: PropTypes.string.isRequired,
    setOpenSection: PropTypes.func.isRequired,
  }).isRequired,
  callApiMethod: PropTypes.string.isRequired,
  callApiSaveCallback: PropTypes.func,
};
SrcmForm.defaultProps = { callApiSaveCallback: undefined };

SrcmForm.InputField = InputField;
SrcmForm.SubForm = SubForm;
SrcmForm.useSrcmApiForForm = useSrcmApiForForm;

export default SrcmForm;

/** @jsx jsx */
import { /* useDispatch, */ useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { selectorGlobalConfig } from "../state/selectors";
// import unsetUser from "../state/actions/unsetUser";
// import { useGlobals } from "../globals";

const useSignOut = () => {
  // const [isFbBlocked] = useGlobals("fb-blocked", true);
  const firebase = useFirebase();
  // const dispatch = useDispatch();
  const { enabledFireStore } = useSelector(selectorGlobalConfig);

  const doSignOut = () => {
    // if (isFbBlocked) {
    //   dispatch(unsetUser());
    // } else {
    firebase.logout();
    if (enabledFireStore) {
      firebase.app("auth").auth().signOut();
    }
    // }
  };

  return { doSignOut };
};

export default useSignOut;
